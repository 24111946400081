#root {
    width: 100%;
    margin: 0;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.ssp-container {
    flex: 1;
    margin-top: 12px;
    display:flex;
}

.text-center {
	text-align: center;
}

.corner-ribbon{
    width: 200px;
    background: #e43;
    position: fixed;
    top: 25px;
    left: -50px;
    text-align: center;
    letter-spacing: 1px;
    color: #f0f0f0;
    font: 300 15px / 40px 'Lato';
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    z-index: 1000;
}

.corner-ribbon.red{
    background: #e43;
}

.ssp-footer {
    background: #fff;
    color: #000;
    font-weight: 300;
    margin-top: 32px;
}

.ssp-footer {
    flex: none;
}

.ssp-footer--container {
    padding: 12px 18px 0.5rem;
}

.ssp-footer {
    color: #000;
    font-weight: 300;
}

.ssp-footer__item {
    min-height: 60px;
}

.ssp-footer__item {
    font-size: 12px;
    letter-spacing: 1px;
    display: table-cell;
    vertical-align: bottom!important;
}

.footer-logo-container{
    bottom:0;
    width:100%;
    text-align:center;
}

.ssp-btn {
    letter-spacing: 1px;
    font-size: 11px;
    padding: 15px 18px;
    line-height: 1.4;
    border: 1px solid;
    border-top-color: currentcolor;
    border-right-color: currentcolor;
    border-bottom-color: currentcolor;
    border-left-color: currentcolor;
    border-radius: 0;
    margin: 6px 2px;
    white-space: normal;
}

.ssp-btn__footer {
    background-color: transparent;
    color: #000;
    border-color: transparent;
    font-size: 12px;
    font-weight: 300;
}

.ssp-footer__item__powered {
    margin-top: 0.5rem;
    position: relative;
}

.footer_link_container {
    height: 100%;
    float:right;
}

.ssp-footer__item__logo {
    height: 40px;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid \9;
    content: "";
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.row-fluid {
    border-radius: 5px;
    margin: 0 auto 0px;
}

.navbar {
	background: transparent;
	position: absolute;
}