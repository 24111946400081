

body .nav-header {
    color: #4b4b4b;
    font-size: 15px;
    font-weight: 300;
}

.tenant_logo_container, .ssp-footer {
    flex: none;
}

.tenant_logo_container .ssp-logo {
    margin-top: 10px;
}

.tenant_logo_container .ssp-logo a {
	display: inline-block;
	padding: 10px;
}

.tenant_logo_container .ssp-logo img {
	max-height: 60px;
}

.tenant_logo_container h1 {
	font-family: 'Open Sans', sans-serif;
	font-weight: 300;
    font-size: 26px;
    text-transform: none !important;
    color: #5b5b5b;
}

/* Development Banner */

.noty-top-global {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: 2000;
    position: relative;
    padding: 0.5em;
}

.noty-top-global svg {
    margin-right: 0.5rem;
}  
  
.noty-top-info {
    color: #00529B;
    background-color: #BDE5F8;
}
  
.noty-top-info, .noty-top-success, .noty-top-warning, .noty-top-error {
    border-bottom: 1px solid;
}
  
.noty-top-error {
    color: #D8000C;
    background-color: #FFBABA;
}
  
.noty-top-warning {
    color: #9F6000;
    background-color: #FEEFB3;
}

.link-button {
    color: #774b08;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
}
  
.link-button:hover {
    opacity: 0.7;
}

/* Navbar */

.navbar-fixed-top {
    z-index: 30!important;
}

.navbar-fixed-top, .navbar-fixed-bottom {
    position: inherit;
    right: 0;
    left: 0;
    z-index: 1030;
    margin-bottom: 0;
}

.navbar-fixed-top .navbar-inner {
	border: 0 none;
	box-shadow: none;
	background: transparent;
}

.drop-menu a {
    font:300 13px / 23px 'Manrope' !important;
    padding:0.2rem 1rem!important;
  }
  
.drop-menu a svg {
    margin-left: 0.2rem;
}

.drop-container-header .dropdown-menu {
    font-size: 4rem!important;
}
  
.drop-container-header .dropdown-menu a {
    padding:0.3rem 1rem!important;
    font-size:1rem!important;
}

.log-button {
    color: rgb(89, 154, 219)!important;
    border-color: rgb(89, 154, 219)!important;
    padding: .2rem .75rem!important;
}
  
.log-button:hover {
    color:white!important;
}
  
/* General */

.text-center {
	text-align: center;
}

/* Footer */

.navbar .container {
    width: 100%;
    max-width: 100%;
}

.ssp-footer--container {
    padding: 12px 18px 0.5rem;
}

.ssp-footer__item {
    min-height: 60px;
}
.ssp-footer__item {
    font-size: 12px;
    letter-spacing: 1px;
    display: table-cell;
    vertical-align: bottom!important;
}

#footer {
    background: #fff;
    color: #000;
    font-family: "Helvetica Neue";
    font-weight: 300;
    overflow: hidden;
    padding: 0 30px 15px;
    text-align: center;
    min-height: 80px;
}

#footer a {
    color: #0a559c;
    text-decoration: none;
}

#footer a:hover {
    text-decoration: underline;
}

#footer .ssp-footer__item  {
    min-height: 40px;
    font-size: 12px;
    display: table-cell;
    vertical-align: bottom!important;
}

.ssp-footer__item {
    display: table-cell;
    vertical-align: bottom!important;
}

#footer .ssp-footer__item__logo {
    height: 40px;
}

#footer .ssp-footer__item__powered {
    display: table-cell;
    vertical-align: bottom;
}

#footer .powered {
    display: table;
}

#footer * {
    font-size: 14px;
}

#footer .row {
    padding: 8px;
}

.ssp-footer__item__logo {
    height: 40px;
}

.ssp-footer__item--links a{
    margin-right: 12px;
    display: inline-block;
    line-height: 50px;
    vertical-align: baseline;
}

.ssp-footer__item__logo--eu {
    height: 30px;
    margin-left: 12px;
}

#footer .col-images a:hover {
    text-decoration: none;
}

@media screen and (max-width: 768px ) {
    .ssp-footer__item--links {
        text-align: center;
        margin: 0 6px;
    }
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid \9;
    content: "";
}

.ssp-footer__item__lang .dropdown-toggle::after {
    content:none!important;
}
.ssp-footer__item__lang {
    width:100%;
    text-align: center;    
}

.ssp-btn {
    letter-spacing: 1px;
    font-size: 11px;
    padding: 15px 18px;
    line-height: 1.4;
    border: 1px solid;
    border-top-color: currentcolor;
    border-right-color: currentcolor;
    border-bottom-color: currentcolor;
    border-left-color: currentcolor;
    border-radius: 0;
    margin: 6px 2px;
    white-space: normal;
}

.footer-logo-container{
    /* position:absolute; */
    bottom:0;
    width:100%;
    text-align:center;
}

.footer_link_container {
    height: 100%;
    float:right;
    text-align: center;
    width:100%;
}

.copyright-funding-footer {
    text-align: center;
    margin-top: 0.5rem;
    letter-spacing:0.03rem;
}

#dropdown-button-drop-up {
    text-align: center;
}