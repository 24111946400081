body {
  width: 100%;
  margin: 0;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Manrope", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
html {
  scroll-behavior: smooth;

}
#root {
  display:flex;
}

.ssp-footer__item__lang .dropdown-toggle::after {
  content:none!important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* General */


/* Main Page */

#footer .ssp-footer__item  {
  min-height: 40px;
  font-size: 12px;
  vertical-align: bottom;
}

.flex-container {
  display:flex;
  flex:1 1;
  margin: 0 2em;
}

.copyright-funding-footer {
  text-align: center;
  margin-top: 0.5rem;
  letter-spacing:0.03rem;
}

/* Modals */

.modal-dialog {
  margin: 10rem auto!important;
} 
.manage-tags-container {
  margin-top:1rem;
}
.manage-tags-container button{
  margin-right:0.5rem;
  margin-bottom:0.5rem;
}

.manage-tags-container svg {
  margin-left:0.5rem;
}


.manage-tags-container-small{
  margin-top:0.5rem;
}


/* Home Page */

.home-services-table {
  table-layout: fixed;
}

.service-overview-no-services {
  padding-left:4rem;
}

.home-services-table td {
  overflow-wrap: break-word;
}
.home-services-table tr th:first-child , .home-services-table tr td:first-child {
  width:10rem;
}
.home-services-table tr th:nth-child(2) , .home-services-table tr td:nth-last-child(2) {
  /* min-width: 8rem; */
  width:20rem;
}
.home-services-table tr th:last-child , .home-services-table tr td:last-child {
  width:13rem;
}

.pagination {
  padding-left: 0.2rem;
  padding-bottom: 0.2rem;
}
.home-search-input {
  margin-top:2rem;
  width:15rem;
  margin-bottom:1rem;
  float:right;
  margin-right: 0.1rem;
}
.pagination .pagination-text-container{
  display: inline!important;
  line-height:2.4rem;
  padding: 0 0.3rem;
  text-align: center;
  vertical-align: middle;
}
.home-pagination-select {
  margin-left:0.5rem;
  width:10rem;
}


.service-table-logo-contianer {
  width:7rem;
  height:7rem;
  background-color:red;
}
.service-hide-logo {
  display:none;
}
.logo-loader-container {
  width:8rem;
  height:8rem;
}

.table-image-container .logo-loader-container {
  height:6rem!important;
}


.logo-loader {
  
  border: 0.3rem solid #f3f3f3; /* Light grey */
  border-top: 0.3rem solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

.table-image-container .logo-loader {

  border: 0.2rem solid #f3f3f3; /* Light grey */
  border-top: 0.2rem solid #3498db; /* Blue */
  width: 35px;
  height: 35px;
}



@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.home-service-logo {
  width: 8rem!important;
}

.home-table-service-name {
  font-size: 1.75rem;
}

.home-container {
  background-color:rgb(245, 245, 245);
  font-weight: 300;
  font-family: "Manrope";
  text-align: left;
}
.home-container h1{
  color: #4DB3CF;
  font-size: 2rem;
  padding-top: 1.5rem;
  margin: 0 0 1rem 0.7em;
  font-weight: bold;
}

.home-container p {
  font-size:1rem;
  margin-left:1.5rem;
  margin-right: 1.5rem;
  padding-bottom: 1.5rem;
}

/* Header */






.outdated-notifications-container {
  padding-top:1rem;
}
.outdated-notifications-input-container svg {
  margin-right:0.5rem;
}
.outdated-notifications-services-container {
  margin-top:2rem;
}
.outdated-notifications-container p {
  margin-top: 1rem!important;
}
.outdated-notifications-services-container table {
  width:auto!important;
  margin-top:1.4rem;
}


.logo {
  margin-top:-2.5rem;
}
.logo a img {
  height: 5.4rem;
}
.main-title {
  color:rgb(91, 91, 91);
  font-family:"Manrope";
  font-weight: 300;
  font-size: 1.9rem;
  margin-top:1rem;
  margin-bottom:2rem;
}

/* Footer */

.footer {
  width:90%;
  margin:auto;
  margin-top:1rem;
  font-family:'Manrope';
  font-weight:300;
}
.logo-grnet {
  width:8rem;
  margin-right:0.5rem;
}
.logo-eu {
  width: 3rem;
}
.footer-links {
  display:flex;
  align-items:center;
  justify-content:flex-end;
  text-align: right;
}
.footer-links a {
  margin-left:0.5rem;
  font-size:1rem;
}
.footer-description  {
  margin-top:0.7rem;
  font-size:0.9rem;
}


/* Form Handler */
.text-comment{
   white-space: pre-line;
}
.jumbotron-comment {
  padding:1rem!important;
  text-align: left;
  max-height:7123rem;
  margin-top: 1rem;
  margin-bottom:0rem!important;
}
.jumbotron-comment h5 {
  font-size:1.3rem;
}
.jumbotron-comment p {
  margin-bottom: 0;
  font-size:1rem;
}



/* Service List */

.expand-reset-filters-container {
  text-align:right!important;
}

.reset-filter-button svg {
  margin-right: 0!important;
}
.reset-filter-button {
  margin:0.4rem;
  font-size: 0.9rem;
  padding: .175rem .55rem!important;
}
.fa-arrow-down {
  margin:0 0 0.1rem 0.4rem!important;
}

.tags-container-servicelist {
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
}

.tags-container-servicelist span {
  margin-right:0.3rem;
  cursor: pointer;
}

.tags-arrow {
  /* padding:0.1 1rem 1rem 0.5rem; */
  padding: 0rem 0.5rem 0.2rem 0.2rem!important;
  display:inline-block;
  vertical-align: top;
  cursor:pointer;
  height:1.8rem;
  width:1.8rem;
  font-size: 1.5rem;
}

.tags-arrow:hover {
  opacity: 0.6;
}

.tags-arrow .fa-arrow-down{
  position:absolute;
  margin-top: -0.25rem!important;
  margin-left: 0.3rem!important;  
}
.tags-arrow .fa-arrow-up{
  position:absolute;
  margin-top: 0.4rem!important;
  margin-left: 0.3rem!important;  
}

.more_info_container {
  display: flex;
  margin: 0 0.5rem 0 0 ;
  justify-content: center;
  align-items: center;
}
.more_info_container .more_info_field {
  padding: 0!important;
  flex-shrink: 0;
}
.service-count {
  display:inline-block;
  font-weight: bold;
  margin-left: 0.3rem;
  margin-top:0.5rem;
  margin-bottom: 0.5rem;
}
.options-bar .row {
  width:100%!important;
  margin-left: 0!important;
}
.options-bar, .links {
  text-align: left;
  font-family:'Manrope';
  font-weight:300;
}
.options-bar button:first-child {
  margin-right:0.5rem;
}
.options-bar button svg {
  margin-right:0.5rem;
}
.options-bar {
  margin:1rem 0!important;
  padding:0.5rem 0;
  border:1px solid rgb(227, 227, 227);
}
.options-bar .col, .options-search {
  padding:0 0.5rem!important;
}
.filter-button {
  float:right;
  color:#0069d9!important;
}
.filters-row {
  margin-top:0.5rem;
}
.filters-col {
  display: inline-block;
  float:right;
}

.fa-arrow-up {
  vertical-align: bottom;
  margin: 0 0 0 0.5rem!important;
}

.petitions-table {
  text-align: left;
  position: relative;
  font-family:'Manrope';
  font-weight:300;
  table-layout: fixed;
  width:100%;
  margin-bottom: 0;
}

.petitions-table tr td:first-child{
  width:120px!important;
}

.petitions-table tr td:last-child{
    padding-left:0!important;
    width:10.5rem!important;
}

.petitions-table img {
  width:100%;
}
.petition-details .table-image-container {
  text-align: center;
}
.petition-details h3{

  font-size:1rem;
  color:rgb(43, 63, 68);
}
.petition-details p {
  font-size:0.8rem;
  color:rgb(153, 153, 153);
}
.table-image-container {

}
.petition-title{
  display:inline-block!important;
}

.petition-actions button {
  font-size: 0.8rem!important;
}
.petition-actions button svg {
  margin:0!important;
}
.badge-container {
  vertical-align: top;
  display:inline-block;
  margin-left:0.5rem;
}
.status-badge {
  margin-top:0rem;
  margin-right: 0.3rem;
}

.controls-col:first-child {
  padding-right:0!important;
  text-align: right;
}

.controls-col:last-child {
  margin-top:-0.5rem;
  padding:0!important;
}

.controls-col:last-child:hover {
    color:#ECECEC!important;
}

.controls-col a {
  display: block;
}

.controls-col .dropdown-item {
  text-align: center;
  padding:0.2rem 1rem!important;
  color: #007bff;

}
.controls-col .dropdown-item a:hover {
  text-decoration:none;
}
.controls-col-buttons button svg {
  margin-right:0.3rem!important;
}

.button-link {
  display: inline-block!important;
  margin-bottom: 0.5rem!important;
}

.notification svg {
  position: absolute;

}
.notification {
  position: absolute;
  margin-left:7rem;
  margin-top:-0.4rem;
}

.drop-container-controls button{
  margin-bottom:0;
}
.drop-container-controls .dropdown-menu {
  padding:0rem;
}

.controls-options-container::after {
  content:none;
}

.controls-options-container {
  color:#495057;
  padding:0.2rem 0.4rem!important;
}
.controls-options-container:hover {
  color:#007bff;
}
.notification .fa-exclamation {
  margin-left: 0.5rem;
  font-size: 0.6rem;
  margin-top: 0.3rem;
  color: white;
  z-index: 10;
}
.notification .fa-circle {
  font-size: 1.2rem;
  color:red;
}
.filter-container {
  margin-left:0.5rem;
  display:inline-block;
  background-color:rgb(245, 245, 245);
  border:1px solid rgb(227, 227, 227);
  vertical-align: top;

  padding:0.3rem 0.5rem;
}
.select-filter-container{
  margin-left:0.5rem;
  display:inline-block;


}
.big-test {
  padding-right:1rem;
  }
.testing {
  position:absolute;
  top:1rem;
  z-index:-1;
}
.pending-filter-active {
  background-color: #D1E7DD;
}

.pending-filter-container svg {
  margin-left: 0.5rem;
}
.dropdown_item_container_pending_filter div {
  display:inline-block;
  width:auto;  

}

.filter-container input {
  vertical-align: top;
  margin-top: 0.3rem;
  margin-left:0.4rem;
}

.drop-container-controls .dropdown-toggle::after {
  content:none!important;
}

.filters-col .btn {
  margin-right: 0!important;
}

.pending-filter-container .dropdown-menu {
  padding: 0.25rem 0;
}
.pending-filter-container .dropdown-item {
  padding: 0.25rem 0.8rem;
}


.pending-filter-container {
  display:inline-block;
}
.pending-filter-container input {
  margin-left:0.5rem;

}

.dropdown-item:hover {
  background-color: #d6d4d4!important;
}


/* Service Form */

.simple_checkbox_container {
  margin-bottom: -0.7rem;
}
.service-form-tags-container small {
  margin-top:0;
}

.tag-button-service-form:disabled {
  opacity: 1!important;
}
.service-form-tags-container {
  margin-top: 0.5rem;
}
.service-form-tags-container button {
  margin-right: 0.5rem;
}
.service-form-tags-container h5 {
  margin-bottom: 1rem;
  display: inline;
}

.service-form-tags-button-container {
  margin-top:0.5rem;
}

.service-form-tags-edit {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  padding-right:0.5rem;
}

.service-form-tags-edit:hover {
  opacity: 0.6;
}

.service-form-tags-container svg {
  margin-left: 0.5rem;
} 

.service-form-tags-edit {
  display:inline-block;
}

.pkce-tooltip {
  margin-top:0.4rem;
  color:#D89C46;
  font-size: 80%;
}
.pkce-tooltip svg {
  margin-right:0.3rem;
}

.reuse-warning {
  margin-top:0rem;
  margin-bottom:0.5rem;
}
.reuse-warning a {
  color:#775a03;
}
.mb-3 .text-muted {
  margin-top: -0.6rem;
}

.input-title-row div:first-child {
  margin-left:0rem!important;
  padding-right:0!important;
}
.input-title-row {
  padding-right: 0.8rem;
}

.more_info_field {
  padding:0 0 0 0.3rem  !important;
  color:#0A559C;
  
}
.more_info_field:hover {
  color:#1377d6;
}


.error-message p {
  margin-bottom:0.3rem;
}


.form-controls-container {
  background-color: #F5F5F5;
  border:1px solid rgb(227, 227, 227);
  padding:0.5rem;
  margin-bottom: 1rem;
  margin-top:-0.5rem;
  text-align: left;
}
.form-controls-container button{
  font-size: 1rem!important;

}
.review-button-expand svg {
  margin-right: 0.5rem;
}

.submit-button {
  margin-right:0.5rem;
}
.submit-buttons button:first-child {
  margin-right:2rem;
}
.submit-buttons button svg {
  margin-right:0.5rem;
}

.form-tabs-container {
  margin-bottom: 1.5rem;
}
.form-tabs {
  margin-bottom:1.5rem!important;
}
.form-tabs-container .tab-content {
  min-height:100vh;
}

.comment-input {
  margin:0 1rem;
}
textarea {
  resize: none!important;
}
.review-controls {

}
.review-controls .form-group > .row{
  margin-bottom: 0.5rem!important;
}
.review-option-desc {
  color:#6b747e;
}
.review-radio-col{
  padding-right: 0.2rem!important;
}
.expand-container {
  margin-top:0.5rem;
}
.review-button-row{
  margin-left: 0.3rem!important;
}
.review-button svg{
  margin-left: 0.5rem;
  vertical-align: top;
  margin-top: 0.1rem;
}
.review-button:focus{
  box-shadow: none!important;
}
.review-button-expand:focus {
  box-shadow: none!important;
}

.review-button-expand {
  padding-right:0.2rem;
}

.review-button-expand svg {
  vertical-align: top;
  margin-top:0.1rem;
}
.review-error {
  color:red;
  margin-left:0.4rem;
  margin-top:0.4rem;
}
.approve-error {
  color:red;
  margin-left:0.4rem;
}
.form_radio_item {
  margin-bottom:0.5rem!important;
}

.form_radio_item input {
  margin-right:0.5rem!important;
}

.public_key_radio {
  display:inline-block;
  margin-right:0.5rem;
}
.public_key_radio input {
  margin-right:0.3rem;
}
.public_key_input {
  margin-top:0.5rem;
}


/* Inputs */
.saml-attributes-table {
  line-height: 1.8rem;
}
.saml-attributes-table tr td:last-child {
  padding: 0.3rem 0.8rem ;
}
.toggle-button .btn {
  opacity: 1!important;
  color:black;
  background-color: white;
} 

.toggle-checked {
  background-color:#0062cc!important;
  color:white!important;
  font-weight: bold!important;
}
.saml-attribute-input {
  margin-bottom:0rem;
}

.disabled-token-auth-method {
  margin-top:0!important;
}


.application-type-container {
  margin-left: -0.1rem;
}
.application-type-container div {
  display: inline;
  margin-right: 0.5rem;
}
.application-type-container input {
  margin-right: 0.2rem;
}

.logo-input {
  max-height: 30rem;
}

.logo-input {
  border: 2px solid #ced4da!important;/* Blue */
  
}
.input-edited {
  border: 1px solid #FFC107!important;/* Blue */
  border-radius: 0.3rem;
  box-shadow: 0 0 0 .2rem
  rgba(255, 193, 7,.25);
}
.input_radio_edited {
  color:#FFC107!important;
  font-weight:bold;
}

.checkbox-edited {
  padding-left:1.9rem!important;
}
.checkbox-more-info-changed {
  margin-bottom: 1rem;
}
.deployment-troubleshooting-alert {
  margin-top:1rem;
}

.select-container {
  display: inline-block;
}

.input-list-table thead {
  border-top-left-radius: 25rem;
}


.input-list-table {
  border:1px solid #9e9e9e!important;
  border-radius:6px;
}

.input-list-table td, .input-list-table th {
  border-left:1px solid #9e9e9e!important;
  border-top:1px solid #9e9e9e!important;
}

.input-list-table th {
  border-top: none;
}

.input-list-table thead {
  border:none!important;
}
.input-list-table thead th:first-child {
  border-left:none!important;

}
.input-list-table thead th {
  border-top:none!important;
}
.input-list-table thead th:last-child {
  border-right: none!important;
}
.input-list-table td:first-child, .input-list-table th:first-child {
   border-left: none;
}
.input-list-table tbody tr:last-child td:first-child {
  border-left:none!important;
}

.input-list-table tbody tr td:first-child {
  border-left:none!important;
  
}
.input-list-table tbody tr td:last-child {
  border-right:none!important;
}
.input-list-table tbody tr:last-child td {
  border-bottom:none!important;
}

.input-list-table-disabled tr:first-child td {
  border-top:none!important;
}















#uri-small-desc{
  margin-top: -0.75rem;
}
.radio-label {
  margin-left: 0.25rem;
}

.radio-buttons {
  display: inline;
  margin-left:0.25rem;

}
.scope-table td:first-child {

  padding-left: 0.8rem!important;
  text-align: left;
}
.addButton {
  font-size: 1rem!important;
  padding:0.08rem 0.33rem!important;
  line-height: 1rem!important;
  margin-bottom:0.5rem!important
}
.removeButton {
  font-size: 1rem!important;
  padding:0.08rem 0.38rem!important;
  line-height: 1rem!important;
}
.radio-group {
  text-align:left;
}


#redirect-uri-label {
  width:100%!important;

}
.label-checkbox {
  margin-top: 0.0rem!important;
  margin-bottom: 1rem!important;
}Loader
.checkboxList {
  text-align: left;
  margin-bottom: 0.37rem;
}
.client-list-table td{
  padding:0.3rem 1rem!important;
  border:1px solid #9e9e9e!important;
}
.client-list-table{

  width:auto!important;
}

.checkboxList input, .checkbox-item  input{
  margin-right:0.7rem;
}
.checkbox-item-edited {
  padding:0.5rem 1rem;
}
.mb-3 {
  margin-bottom: 0.4rem!important;
}
.spacing-bot-contact {
  margin-top:0.9rem!important;
  margin-bottom:0.9rem!important;
}
.spacing-bot {
  margin-bottom: 0.3rem!important;
}
.uri-buttons{
  padding-left:0!important;
}
.petition-details h3{

  font-size:1rem;
  color:rgb(43, 63, 68);
}
.petition-details p {
  font-size:0.8rem;
  color:rgb(153, 153, 153);
}

.checkbox {
  text-align:left;
}


.checkbox label {
  margin-left:0.7rem!important;
}
.custom-radio {
  text-align:left;
}
.custom-radio label {
  margin-right:0.25rem;
  margin-left:0.25rem;
}
.time-input {
  text-align: left;
}
.time-input .col-sm-4 {

  display:inline-block;
  padding-left:0;
}
.warning-message {
  color:#D89C46;
}
.warning-message svg {
  margin-right:0.5rem;
}
.error-message, .error-message-list-item, .notifications-error{
  color:#dc3545;
}
.error-message-list-item {
  margin-top:-0.7rem!important;
  margin-bottom: 0.3rem!important;
}
.error-td div {
  margin:0!important;
}

.error-tr td:first-child {
  background-color: #ffb3a6;

}

.invalid-input input {
  border:solid 1px #dc3545!important;
  border-radius: 0.25rem
}
.select-col {
  text-align: left;
}
.post-button{
  align-self:left;
  margin-left:-3rem;
  margin-right:1.5rem;
}
form {
  margin-top:1.5rem;
}
.dropdown-toggle svg {
  margin-left:0.5rem;
}

.nav-side {
  
  width:20vw!important;
  background-color:rgb(245, 245, 245);

  font-family:'Manrope';
  font-weight:300;
  border:1px solid rgb(227, 227, 227);
  margin-right:2rem;
  text-align: left;
  min-width: 8rem;
  margin-bottom: 1rem;
}
.nav-side a:hover, .nav-side a:active {
    background: #f2f2f2;
    text-decoration: none;
    height:auto;
}
.nav-side a {
  display:block;
  margin-top: 0!important;
  padding:0.2rem 0;
  padding-left: 1.3rem;
}

.nav-side-category div {
  margin-left: 1rem;
}

.nav-side-category {
  padding-bottom:0.5rem;
  border-bottom:1px solid rgb(227, 227, 227);
}


.nav-side-category:last-child {
  border:none;
}

.nav-side-notification .fa-exclamation {
  position:absolute;

  font-size: 0.5rem;
  color: white;
  z-index: 10;
}
.nav-side-notification .fa-circle {
  position: absolute;
  margin-top:-0.15rem;
  margin-left:-0.3rem;
  font-size: 0.8rem;
  color:red;
}


.nav-side-notification {
  position: absolute;
  margin-left:0rem!important;
  display: inline-block;

}

.outdated-notifications-main-row {
  padding-left:1rem;
}

.nav-title {
  color:#4b4b4b;
  font-size: 1rem;
  font-weight: 300;
  font-family: "Manrope";
  line-height: 3rem;
  margin-bottom: -0.5rem;
}


.content-container {
  width:100%;
  margin:auto;
  margin-top:0;
  max-width:1600px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}


.links {
  font-size:1rem;
  line-height: 1rem;
  padding:0.6rem;
  background-color:rgb(245, 245, 245);
}
.link-seperator {
  margin-left: 0.3rem;
  margin-right:0.3rem;
}




 thead td {
  border:none!important;
}
tbody tr td {
  border-right: none!important;
  border-left: none!important;
}
tbody tr td:last-child {
  border-left: none;
}






.submit-button {
  margin-right:0.5rem;
}
.submit-buttons button:first-child {
  margin-right:2rem;
}
.submit-buttons button svg {
  margin-right:0.5rem;
}

.organizations-input {
  margin-bottom: 0;
}

#hide_input {
  display:none;
}

.nav-side a{
  margin-top: 0.5rem;

}
/* User Info */

.user-text-info {
  display:none;
}
.dl-horizontal {
  margin-top:1.5rem;
}

.dl-horizontal dt {
  width:20%;
  float:left;
  text-align: right;
  clear: left;
}
.dl-horizontal dd {
  text-align: left;
  display:inline-block;
  width:70%;
  margin-left:0.3rem;
  height:20px;
}
.dl-text-info {
  font: 300 15px / 23px 'Manrope';
  color:rgb(43, 63, 68)!important;
}

.dl-text-error {
  font: 300 15px / 23px 'Manrope';
  color:#dc3545!important;
  font-weight: bold;
}

.dl-text-success {
  font: 300 15px / 23px 'Manrope';
  color:#28A745!important;
  font-weight: bold;
}

.text-info {
  font: 300 15px / 23px 'Manrope';
}

/* Form Tabs Review */


.input-new {
  border-color:#28A745!important;
  box-shadow: 0 0 0 .2rem
  rgba(40, 167, 69,.25);
}
.input-deleted {
  border-color:#DC3545!important;
  box-shadow: 0 0 0 .2rem
  rgba(220, 53, 69,.25);
}
.remove-border-right {
  border-right:1px solid #ced4da!important;
  clip-path: inset(-.2rem 0px -0.2rem -0.2rem);
}
.remove-border-left {
  margin-left:-1px;
  border-left:1px solid #ced4da!important;
  border-radius:0 0.25rem 0.25rem 0!important;
  clip-path: inset(-.2rem -0.2rem -0.2rem -0rem);
}

.input-list-table td, .input-list-table th{
  border:1px solid #9e9e9e!important;
}
.row-deleted {
  background-color: #ffb3a6!important;
}
.row-deleted td {
  border-color:#dc3545!important;
}
.row-added:hover {
  background-color:#4ab563!important;
}
.row-deleted:hover {
  background-color:#f29587!important;
}
.row-added {
  background-color: #75D88A!important;
  border-color:#28a745!important;
}
.row-added td {
  border-color:#28a745!important;
}
.row-edited td {
  border-color:#ad8305!important;
}
.row-edited {
  background-color:#ffd556!important 
}
.row-edited:hover{
  background-color: #ffc107!important;
}

.input-list-table {
  max-width: 100%;
  display:block;
  width: 100%;
  table-layout: auto;
  margin-bottom: 0rem;
}

/* Loader */

.loader-container {
  position:fixed;
  left:50%;
  margin-left: -13.5rem;
  top:50%;
  margin-top:-2.5rem;
  width:27rem;
  height:5rem;
  background: rgb(235, 235, 235);
  border:1px solid rgb(215, 215, 215);
  z-index: 200;
  text-align: left;

}
.loader-container h3 {
  margin-left:3.5rem;
  font-size: 1.6rem;
  font-weight: 300;
  font-family: "Manrope";
  width:auto;
  margin-top: 1.44rem;
  margin-bottom:  1.4rem;
  display: inline-block;
}
.loader-container div {
  vertical-align: top;
  margin-left:2rem;
  margin-top: 1.4rem;
  display: inline-block;
}


.loader {
  position:relative;
  float:right;
  margin-top:-1.8rem;
  margin-right:-2rem;
  border: 0.3rem solid #f3f3f3; /* Light grey */
  border-top: 0.3rem solid #3498db; /* Blue */
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Inputs Hide */
.input-hide {

  background: none!important;

}

/*  */
.progress-bar-container {
  width:90%;
  height:4vw;
  margin:auto;
}
.loading-container h1 {
  font-family:'Manrope';
  font-weight:300;
  font-size: 1.5vw;
  text-align: left;
  margin-top:1vw;
  margin-left: 5%;
}
.loading-container {
  margin-top:1vw;
  border:1px solid rgb(227, 227, 227);
  background-color: #F5F5F5;
}

.pending-button {
  float:right;
}


/* Pettition Form */




/* Inputs */


/* History */
.history-container {
  font-weight: 300;
  font-family: "Manrope";
  text-align: left;
  margin-top:1rem;
  display:inline-block;
  float:left;
  font-size: 1.1rem;

}
.history-item {
  border:1px solid rgb(227, 227, 227)!important;
  border-radius: 0rem;
  background-color:#F5F5F5;
  padding:0.5rem 1rem;
  margin-bottom:1rem;
  left:0;
}
.history-item svg {
  margin-left:0.3rem;
}
.history-item:hover {
  background-color: #ECECEC;
}
.history-date {
  margin-left:1rem;
  margin-right:1rem;
}
.history-svg-edit {
  color:#FFC107;
}
.history-svg-create {
  color:#28A745;
}

.history-table {
  font-weight: 300;
  font-family: "Manrope";
  text-align: left;
  margin-top:1rem;
  width:40rem!important;

}
.history-table Button {
  font-size:0.8rem;
}
.history-table Button svg {
  margin-right: 0.5rem;
}
.history-color-create {
  font-size: 1.2rem;
  font-weight:700;
  color:#28A745;
}
.fake-link:hover {

    color: #0056b3;
    text-decoration: underline;
}
.fake-link {
  cursor: pointer;
  color: #007bff;
  text-decoration: none;
}
.cursor-pointer {
  cursor: pointer!important;
}

.edit-tabs {
  margin-top:1rem
}

/* Not sure */

#hide-tooltip {
  display: none;
}
.tooltip .arrow{
  height: 0.5rem!important;
}

/* Lang fix */
html:lang(gr) .home-container h1 {
  font-size: 10rem;
}

/* Loading Page */
.loading-page {
  position: absolute;
  top:0;
  left:0;
  width:100vw;
  height:100vh;
  background-color: white;
  z-index:40;
}

.loading-page div {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    margin:auto;
    width:10vw;
    height:10vw;
    border-width: 0.65em;
    animation: spinner-border 1.5s linear infinite;
}

.ssp-footer {
  z-index: 50!important;
}



/* Groups PPage */
.group_page_main_title {
  margin-top:1rem;
}

.warning-red {
  margin-top:0.4rem;
  color:#DC3545;
}
.groups-table {
  width:auto;
  margin-top:1rem;
  min-width: 0;
  max-width: 40rem;
}
.groups-table td,.groups-table th{
    padding: 0.5rem;
    width:auto;
    white-space: nowrap;
}
.groups-table th{
  text-align:center;
  width:0;
}
.groups-table th:nth-child(3) {
  width:9rem;
  text-align:  center;
}
.groups-table th:nth-child(3) {
  width:3rem!important;
  text-align:  center;
}
.groups-table td:nth-child(3) {
  padding:0.7;
  color:#28A745;
  text-align:  center;
}
.groups-table td{
  line-height: 1.6rem;
  text-align: center;
}
.groups-table button {
  padding:0.1rem 0.5rem 0.1rem 0.5rem;
}

.groups-table button:nth-child(2) {
  padding:0.1rem 0.4rem 0.1rem 0.4rem;
}
.group_invite_row {
  margin:0;
  margin-left:0.3rem;

  margin-top:1rem;
  max-width:50rem;
}
.group_invite_row .input-group {
  padding: 0rem!important;
  margin-bottom:0.2rem!important;
}

.invitation-error {
  font-size: 0.8rem;
  margin:0;
  margin-left:0.5rem;
  color:#DC3545;
  font-weight: bold;
}
.invitation_alert {
  margin-top:1rem;
}
.invitation_alert span {
  font-weight: bold;
}


.group_title {
  margin-top: 1rem!important;
}


.invitation-container {
  margin-top:1rem;
  padding:0.5rem;

  border: 1px solid rgb(227, 227, 227);
  background-color: rgb(245, 245, 245);
}
.invitation-container button{

    margin-left: 1rem;
}

.invitation-container h3 {
  margin-left:0.5rem;
  margin-top:0.4rem;
  height:100%;
  display: inline-block;
  font-size: 1.1rem;
}


.confirmation-table td:first-child {
  text-align: right;
  padding-right: 0.5rem;
  font-weight: bold;
}


.groups-invite-table button {
  margin-right: 0.5rem;
}
.groups-invite-table td:nth-child(2) {
  color:#28A745;
}

.not_found_container {
  margin-top: 5rem;
  margin-left: 5rem;
}
.not_found_container span {
  color:#7c7c7c;
}

.error-loader {
  border: 0.3rem solid #f3f3f3; /* Light grey */
  margin-top: 0.4rem;
  margin-left:0.3rem;
  border-top: 0.3rem solid #B1737A; /* Blue */
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  animation: spin 2s linear infinite;
}
.error-action-alert {
  border-color:     #f5c6cb!important;
  border-width: thin!important;
}
.error-action-alert-hidden {
  border:none!important;
  background-color: #F8D7DA!important;
  margin-bottom:0rem!important;
}

.warning-action-alert {
  border-color: #ffeeba!important;
  border-width: thin!important;
}
.warning-action-alert-hidden {
  border:none!important;
  background-color: #FFF3CD!important;
  margin-bottom:0rem!important;
}
.leave-group-button{
  padding: 0.1rem 0.35rem 0.1rem 0.35rem!important
}



.table-overlay {
  position: absolute;
  top: 3rem;
  width: 100%;
  height: -webkit-calc(100% - 3rem);
  height: calc(100% - 3rem);
  border:none!important;
  background-color: rgba(255, 255, 255, 0.7)!important;
}

.table-overlay td{
  border:none!important;
}


.fake-link {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

.alert_fake_link {
  color:#775a03;
  font-weight: bold;
  cursor:pointer;
}
.alert_fake_link_primary {
  color:#007bff!important;
  font-weight: normal!important;
  text-decoration: none!important;
  cursor:pointer;
}

.alert_fake_link:hover {
  text-decoration: underline;
}
.alert_fake_link_primary:hover {
  text-decoration: underline!important;
  color:#0056b3;
}
.copy_button {
  margin-left:0.5rem!important;
  margin-bottom:0.1rem;
  padding: 0rem 0.4rem;
}

.required_indicator {
  color:red;
  margin-left:-0.6rem;
  margin-top:0.2rem;
}

.integration-environment-container {
  text-align: left;
}

.copy-clip-button {
  font-size: .875rem;
}

.form-submit-cancel-container button svg {
  margin-right:0.3rem!important;
}


/* Manage Owners */
.manage_owners_tabs {
  margin-top: 1rem;
}


/* Notifications */

.email-body-input {
  height:20rem!important;
  width: 100%!important;;
}


.email-body-input-container {
  max-width: 100%!important;
  width: 100%!important;
}

.notifications-right-row {
  margin-left:0!important;
}

.outdated-notifications-container {
  padding-left:0.5rem;
}
.outdated-notifications-input-container {
  width:20rem;
}

.outdated-notifications-container h1 {
  margin-top:1rem;
  color: #035081;  
  font-weight:700;
}

.notifications-main-row {
  height:100%;
  flex:1 1;
  margin-top: 1rem;
}
.notifications-main-row h1 ,.notifications-main-row h3  {
  color: #035081;
  font-weight:700;
} 
.notifications-main-row h3 {
  margin-top:3rem;
  min-width: 100%;
}
.notifications-right-col , .notifications-left-col {
  padding-left: 2rem!important;
}

.notifications-secondary-col {
  padding-left: 0rem!important;
}
.notifications-left-col {
  border-right: 2px solid #DDE1E6;
  min-width: 12rem;
}

.alert {
  margin-bottom: 0!important;
  margin-top: 1rem!important;
}

.notifications-left-col hr,.notifications-right-col hr {
  margin-left: 0;
  width:100%;
  margin-right: 0;
}
.notifications-right-col .row {
  margin-top: -1rem;
  margin-right: 0!important;
}
.notifications-input-container {
  margin-top:1rem;
  flex:1 1 100%;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  display:block;
}
.notifications-input-container div {
  padding:0.2rem 0.2rem 0.2rem 0.2rem; 
}
.notifications-error-up {
  margin-top: -0.3rem;
}

.notifications-label {
  font-size: 1.2rem;
  float:left; 
  margin-top: 0.3rem;
  margin-right: 1rem;
}

.notifications-label span {
  color:red;
}
.notifications-input {
  overflow: hidden;
  max-width:400px;
  margin-left:1.2rem;
  padding-bottom: 0.5rem;    
}


.notifications-error {
  height:auto!important;
  text-align: left!important;
}


.split-to-rows {
  margin-top:0rem;
  padding-bottom: 1rem;
}

.split-to-rows .notifications-label { 
  text-align: left;
  margin-top:0rem;
  float:none;
}

.split-to-rows div {
  height:auto;
}
.email-body-notifications-input-container {
  margin-top:1rem!important;
}

.recipients-container div{
  display: flex;
  align-items: stretch;
  text-align: center;
 

}

.recipients-container {
  margin-left: 0;
  margin-bottom:-2rem;
}
.notifications-help {
  margin-top: -0.3rem;
  color:6c757d;
  font-size: 90%;
}


.recipients-container svg {
  margin-top: 0.4rem;
  margin-right: 0.5rem; 
  font-size: 1.5rem;
}

.recipients-container button {
  color: #fff!important;
  opacity: 1!important;
  background-color: #007bff!important;
  border-color: #007bff!important;
  font-weight:bold!important ;
  margin-left: 0.5rem;
}

.recipients-container button:hover {
  color: #fff!important;
  opacity: 1!important;

  background-color: #007bff!important;
  border-color: #007bff!important;
}

.split-to-rows .notifications-input {
  margin-left: 0;
  margin-top: 0.5rem;
  max-width: 900px;
  padding:0.2rem 0.2rem 0.2rem 0.2rem;
}

.notifications-right-col .submit-button {
  margin-top:1rem;
  float:right;
  margin-bottom: 0.5rem;

}
.notifications-right-col .submit-button svg {
  margin-right:0.5rem;
}

.notifications-main-row form {
  display: flex;
  flex: 1 1;
  flex-wrap: wrap;
}

.checkbox-cc .form-check-input{
  margin-left:0!important;
}
.checkbox-cc label {
  margin-left: 1.5rem!important;
}


  .alert_bar_displacement {
    margin-top: 2.5rem;
  }

  .link-button {
    color: #774b08;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
  }
  .link-button:hover {
    opacity: 0.7;
  }


/* Media */

@media only screen and (max-width: 1058px) {
 
.ssp-container > .flex-container {
  flex-wrap: wrap;
} 
}


@media only screen and (max-width: 1300px) {
 
  .split-to-rows-media {
    margin-top:0rem;
    padding-bottom: 1rem;
  }
  
  .split-to-rows-media {
    margin-top:0rem;
    padding-bottom: 1rem;
  }
  
  .split-to-rows-media .notifications-label { 
    text-align: left;
    margin-top:0rem;
    float:none;
  }  
    
}

@media only screen and (max-width: 700px) {
 
  .split-to-rows-media {
    margin-top:0rem;
    padding-bottom: 1rem;
  }
  
  .split-to-rows-media {
    margin-top:0rem;
    padding-bottom: 1rem;
  }
  
  .split-to-rows-media .notifications-label { 
    text-align: left;
    margin-top:0rem;
    float:none;
  }  
    
}


@media only screen and (max-width: 1000px) and (min-width:450px){
 
  .notifications-left-col {
    min-width: 20rem;
  }
    
}

.attribute-table {
  table-layout: fixed;
}

footer .row {
  padding: 8px;
  justify-content:center!important;
}